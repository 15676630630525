import React, { useState } from "react";
import { graphql, Link } from "gatsby";

import { Container, Accordian, ImageGallery, VideoGallery, ContactForm, SEOProperty, Maps, SocialShare, DetailTabs } from "../components";

//CRE components

import { PropertyDetail, Units, Contacts, RelatedProperty } from "cre-plugin/src/components/propertyComponents";

//Styles
import { StyledPage, Grid2, Grid21, Grid27, StyledProperty } from "../components/css";

const { makePropertyPagePath } = require("cre-plugin/src/components/utils/urlGenerator");

export default function PropertyToLet({ data }) {

  var property = data.property.nodes;
  var relatedProperties = data.related.nodes;

  //On page SEO
  const SEO = {
    street_address: property[0].street_address,
    suburb: property[0].suburb,
    city: property[0].city,
    latitude: property[0].latitude,
    longitude: property[0].longitude,
    property_name: property[0].property_name,
    marketing_heading: property[0].marketing.property_marketing_heading,
    marketing_description: property[0].marketing.property_marketing_description,
    category: property[0].property_category.replace("VacantLand", "Vacant land"),
    id: property[0].property_gmaven_key,
    deal: "to let",
    price: property[0].gross_price,
    keywords: `${property[0].property_name}, ${property[0].property_category.replace("VacantLand", "Vacant land")}, ${property[0].suburb}, for sale`,
    url: makePropertyPagePath(property[0], "ToLet", "property"),
    best_image: property[0].best_image,
    contact_name: property[0].property_responsibility ? property[0].property_responsibility.length > 0 ? property[0].property_responsibility[0].name : null : null,
    contact_email: property[0].property_responsibility ? property[0].property_responsibility.length > 0 ? property[0].property_responsibility[0].email : null : null,
    contact_telephone: property[0].property_responsibility ? property[0].property_responsibility.length > 0 ? property[0].property_responsibility[0].cell_number : null : null
  }

  //Images
  var property_images = [];

  if (property[0].property_images) {
    property_images = property[0].property_images;
  }

  var showExt = property_images.filter(image => (image.type === "exterior" || image.type === "aerial")) || [];
  var showInt = property_images.filter(image => image.type === "interior") || [];
  var showFloor = property_images.filter(image => image.type === "floor_plan") || [];
  var showVid = property[0].property_video || [];

  //Pre-select the first category of media
  const imageBoolean = {
    0: showExt.length > 0,
    1: showInt.length > 0,
    2: showFloor.length > 0,
    3: showVid.length > 0
  }

  const firstBoolean = Object.keys(imageBoolean).find(key => imageBoolean[key] === true);
  const showFirst = parseInt(firstBoolean);

  const [open, setOpen] = useState(showFirst);

  //Responsibility
  var property_responsibility = [];

  if (property[0].property_responsibility) {
    property_responsibility = property[0].property_responsibility;
  }

  return (
    <Container page="content" breadcrumbs={true} breadData={{ type: "property", deal: "ToLet", data: property[0], mainCat: "Vacancies" }}>

      {/* Page meta data (title, description, etc.)*/}
      <SEOProperty
        street_address={SEO.street_address}
        suburb={SEO.suburb}
        city={SEO.city}
        latitude={SEO.latitude}
        longitude={SEO.longitude}
        property_name={SEO.property_name}
        marketing_heading={SEO.marketing_heading}
        marketing_description={SEO.marketing_description}
        category={SEO.category}
        id={SEO.id}
        deal={SEO.deal}
        price={SEO.price}
        keywords={SEO.keywords}
        url={SEO.url}
        best_image={SEO.best_image}
        contact_name={SEO.contact_name}
        contact_email={SEO.contact_email}
        contact_telephone={SEO.contact_telephone}
      />

      <StyledPage>

        <StyledProperty>

          <Grid2>



            <div>

              <div className="imageDiv">

                <Accordian open={open} setOpen={setOpen} ext={showExt.length > 0} int={showInt.length > 0} fp={showFloor.length > 0} vid={showVid.length > 0} />

                {/* Exterior images */}
                <ImageGallery title={property[0].property_name} data={showExt} type="exterior" open={open} />

                {/* Interior images */}
                <ImageGallery title={property[0].property_name} data={showInt} type="interior" open={open} />

                {/* Floor plan images */}
                <ImageGallery title={property[0].property_name} data={showFloor} type="floor_plan" open={open} />

                {/* Video */}
                <VideoGallery data={showVid} show={showVid !== null} open={open} />

              </div>

            </div>

            <div className="infoBlock">

              <h1>{property[0].property_name}</h1>

              <div>

                <PropertyDetail property={property} deal="ToLet" type="property" />

                <Units units={property} />

              </div>

            </div>

          </Grid2>

          <Grid2>

            <div>

              <h4>Contact <span>us</span></h4>

              <Contacts contacts={property_responsibility} />

            </div>

            <div>

              <h4>Get <span>in touch</span></h4>

              <ContactForm contacts={property_responsibility} data={`Please contact me regarding leasing a space in ${property[0].property_name}`} />

            </div>

          </Grid2>



          <div>

            <h4>Map</h4>

            <Maps lat={property[0].latitude} lng={property[0].longitude} />

          </div>

          <RelatedProperty property={relatedProperties} deal="ToLet" />

          {/* <div style={{ overflow: "hidden" }}>
          {previous && showPrevNext === 1 && (
            <div style={{ float: "left" }}>
              <Link to={makePropertyPagePath(previous, "ToLet", "property")} rel="prev">
                <img src={previous.best_image} alt={previous.property_name} width="60px" />
                <p>Previous property: </p>
                <p>{previous.property_name}</p>
                <p>{previous.property_category}</p>
                <p>{previous.suburb}</p>
              </Link>
            </div>
          )}
          {next && showPrevNext === 1 && (
            <div style={{ float: "right" }}>
              <Link to={makePropertyPagePath(next, "ToLet", "property")} rel="next">
                <img src={next.best_image} alt={next.property_name} width="60px" />
                <p>Next property: </p>
                <p>{next.property_name}</p>
                <p>{next.property_category}</p>
                <p>{next.suburb}</p>
              </Link>
            </div>
          )}
        </div> */}

        </StyledProperty>

      </StyledPage>

    </Container>
  )
}

export const query = graphql`
  query($id: ID!, $property_category: String!, $suburb: String!) {
    property: allD9PropertyToLet(filter: {property_gmaven_key: {eq: $id}}) {
      nodes {
        objectID
        gmaven_mapped_key
        property_gmaven_key
        property_name
        street_address
        suburb
        city
        province
        country
        cluster
        latitude
        longitude
        marketing {
          property_marketing_description
          unit_marketing_heading
          unit_marketing_description
        }
        total_property_gla
        total_erf_extent
        property_category
        property_sub_category
        unit_category
        secondary_category
        property_featured
        best_image
        status
        status_date
        web_ref
        gross_price
        net_price
        available_type
        available_date
        unit_id
        complex_space
        property_responsibility {
          gmaven_contact_key
          cell_number
          email
          name
          image
          role
        }
        min_gla
        max_gla
        sub_divisible
        this_unit_can_be_leased_by_itself
        combinable
        property_video
        video
        property_virtual_tour
        virtual_tour
        property_update_date
        unit_update_date
        property_images {
          image_path_url
          type
        }
        office_features {
          has_aircon
          no_floors
          lift_count
          lift_cores
          has_internet
          internet_provider
        }
        industrial_features {
          power_phase
          power_output
          power_unit
          has_yard
          height_to_eaves
          lux_level
          lux_description
          gantry_cranes
          floor_load_capacity
        }
        parking {
          property_parking
          property_parking_ratio
          property_parking_open
          property_parking_basement
          property_parking_covered
          property_parking_shade
          property_parking_tandem
        }
        general_features {
          property_naming_rights
          security_guards
          security_hours
          security_responsibility
          has_security
          security_infrustructure
          green_certification
          green_certification_rating
          has_generators
          solar
          backup_water_supply
          canteen
          property_kitchenette
          gym
          boardroom
          showers
        }
      }
    }
    related: allD9PropertyToLet(filter: {property_gmaven_key: {ne: $id}, property_category: {eq: $property_category}, suburb: {eq: $suburb}}) {
      nodes {
        id
        property_gmaven_key
        property_name
        suburb
        min_gla
        max_gla
        gross_price
        net_price
        property_category
        best_image
      }
    }
  }
`